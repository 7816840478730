<template>
  <v-layout column justify-center fill-height class="text-input-mission">
    <!-- Mission question -->
    <v-flex d-flex class="mission-instructions">
      <ResizableText :message="instructions" />
    </v-flex>
    <v-flex shrink>
      <TextField
        class="mission-text-feild"
        :placeholder="placeholder"
        :status="status"
        :show="showTextField"
        :buttonText="'SUBMIT'"
        @onSubmit="onSubmit"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex"
import { GameMixin } from "@/mixins"
import ResizableText from "./ResizableText.vue"
import TextField from "./TextField.vue"
export default {
  name: "TextInput",
  mixins: [GameMixin],
  components: {
    ResizableText,
    TextField
  },
  props: {
    mode: String
  },
  // mounted() {
  //   // save the initial length
  //   const waitingTextLength = this.waitingText.length
  //   this.interval = setInterval(() => {
  //     if (this.waitingText.length > waitingTextLength + 2)
  //       this.waitingText = this.waitingText.substr(0, waitingTextLength)
  //     else this.waitingText = this.waitingText + "."
  //   }, 600)
  // },
  // destroyed() {
  //   clearInterval(this.interval)
  // },
  data() {
    return {
      // interval: null,
      waitingText: "Waiting on Scribe...",
      lastIncorrectPlays: 0
    }
  },
  computed: {
    ...mapGetters([
      "missionSuccess",
      "missionCompleted",
      "missionPlaysArray",
      "missionAnswers",
      "getCurrentMission"
    ]),
    status() {
      if (this.missionCompleted && this.missionSuccess) {
        return "correct"
      } else if (this.missionCompleted) {
        return "wrong"
      } else if (!this.isHost && this.isScribe && this.mode !== "explain") {
        return "active"
      } else {
        return ""
      }
    },
    instructions() {
      return this.getCurrentMission.instructions
    },
    showTextField() {
      return this.mode !== "explain" ? "show" : ""
    },
    canPlay() {
      return (
        this.mode === "play" || this.mode === "huddle" || this.mode === "social"
      )
    },
    placeholder() {
      if (!this.isScribe && !this.missionAnswers.length) {
        return this.waitingText
      } else if (this.missionCompleted) {
        return "Submitted"
      } else if (this.isScribe && this.canPlay) {
        return "Enter here..."
      } else {
        return ""
      }
    },
    isScribe() {
      return this.$store.getters.isScribe
    },
    isHost() {
      return this.user.role == "facilitator"
    }
  },
  methods: {
    async onSubmit(data) {
      if (!this.isScribe || this.missionCompleted)
        return console.log("No rights for submit")
      if (!data) return console.log("Won't submit an empty string")
      this.answer = data
      await this.checkAnswer()
      this.answer = ""
    }
  }
}
</script>

<style lang="scss">
.text-input-mission {
  .mission-instructions {
    padding: 25px 10px 5px;
  }
  .mission-text-feild {
    position: relative;
    margin-left: 60px;
    margin-right: 6px;
    margin-bottom: 6px;
    transform: translateY(10px);
    visibility: hidden;
    opacity: 0;
    transition: all 0.25s ease-out;
  }
}
</style>
